import React from 'react';
import '../Styles/AboutUs.css';
import AboutBanner from "../Assets//About/aboutbanner.png";
import AboutBannerMobile from "../Assets//About/aboutBannerMobile.png";


function AboutUs() {
    return (
        <div>
            <div className='bannerMainAbout'>
                <img src={AboutBanner} className="about_main_banner desktop" alt='mahindra blues about banner' />
                <img src={AboutBannerMobile} className="about_main_banner mobile" alt='mahindra blues about banner' />
            </div>
            <section className='aboutPage'>
                <h3 data-aos="fade-up"
                    data-aos-duration="2000" className='headingAbout mBottom'>About Festival</h3>
                     <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para_bold'>"THE BLUES LIVE HERE"</p>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>The Mahindra Blues Festival is not just another music festival. </p>
                     <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>It’s a cultural movement. </p>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>In the last 11 years of its existence, the Mahindra Blues Festival has put the blues genre on the global map and is one of the largest blues festivals in Asia.<span className='bold'> The Mahindra Blues community is the world’s largest online community</span>, with over 2,00,000 plus followers. An iconic 70-year Bollywood Studio is the home of The Mahindra Blues Festival and is held annually in the second weekend of February.</p>

                <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>Not just Indian audiences but folks from all over the world have been regulars at the Mahindra Blues Festival for the last decade. The festival vibe across the multiple music stages, courtyard, and garden is accompanied by world-class food courts and malts, which completes the beautiful ambience. </p>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>Mahindra Blues Weekend Chicago is held twice a year in partnership with “Buddy Guys Legends”, the mecca of blues to promote blues talent in the USA.</p>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>The Mahindra Blues Band Hunt is a talent discovery platform to find and encourage new Indian blues artists from across the country and provide them with the platform of the Mahindra Blues Festival to exhibit their talent alongside international music legends.</p>

                <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>The “Mahindra Blues in Schools” is a pilot initiative in partnership with “Nanhi Kali” to build the confidence of the girl child from weaker strata of society and give them a public platform at the Mahindra Blues Festival to allow them to exhibit their talent and feel part of the blues movement.</p>

                <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>The Mahindra Blues All-Star-Jam is one of a kind and has an unbroken tradition started by the legendary multi-Grammy award winner and friend of the Mahindra Blues, Mr Buddy Guy. This is the only festival where all the artists who have performed on both days come back in an all-star jam to bring the curtains down on the festival. 
                    </p>

                <p data-aos="fade-up"
                    data-aos-duration="2000" className='about_para'>The Mahindra Blues Festival is a feast for the mind, body, and soul! </p>
            </section>
        </div>
    )
}

export default AboutUs