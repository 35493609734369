import React from 'react';
import '../Styles/Footer.css';
import hyperlinkLogo from "../Assets/hyperlink.svg";
import { Row, Col } from 'react-bootstrap';

function Footer() {
    return (
        <div> 
            <div className="moveProps slideright-r"></div>
            <section id='contactus' className='footer'>
                <div className='footerTxt'>
                    <h3 className='mainHeadFooter'>WRITE TO US</h3>
                    <p className='footerPara'>We'd like to hear from you. Send us your suggestions at  
                    <a href="mailto:mahindrablues@gmail.com" className='linkFooter'> mahindrablues@gmail.com</a>
                    </p>
                    <p className='footerPara'>To know more about Mahindra, please visit our corporate website at 
                    <a href='https://www.mahindra.com/' target="_blank" className='linkFooter'> www.mahindra.com</a> </p>
                    <div className='hyperlinkBlock'>
                        <a href='https://www.hyperlink.co.in/' target="_blank" className='hyperlinkStyle'>
                            <img src={hyperlinkLogo} alt="promoted & Produced by hyperlink" className='hyperlinkImgStyle'></img>
                        </a>
                    </div>
                </div>
                <hr className='divider'></hr>
                <Row className='copyrightSTop'>
                <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                        <p className='copyrightTxt'>Copyright 2022 Mahindra & Mahindra</p>
                    </Col>
                    <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                        <div className='alignCopyrightTxt'>
                            <a href='/privacypolicy' className='footerLinksC'>Privacy Policy</a>
                            <a href='/termsofuse' className='footerLinksC spacearoundF'>Terms of Use</a>
                            <a href='/disclaimer' className='footerLinksC'>Disclaimer</a>
                        </div>
                    </Col>
                </Row>
            </section>
            
        </div>
    )
}

export default Footer