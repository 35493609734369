import React from 'react';
import '../Styles/NewsStyle.css';
import img04 from "../Assets/Home/news/img04.png";
import img004 from "../Assets/Home/news/img004.jpeg";
import vptb from "../Assets/Home/news/vptb.svg";
import { Row, Col } from 'react-bootstrap';


function SugarayPunch() {
    return (
        <div>
            <section className='newsContainerMain'>
                <div>
                    <div className='center'>
                        <img src={vptb} className="posterNews" alt='venky pens the blue poster' />
                    </div>

                    <p data-aos="fade-up"
                        data-aos-duration="2000" className='caSubHead'>The Sugaray 'Punch'</p>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <p className='newsParagraph'>It takes me completely by surprise, for this jolt of energy was not what I was expecting. The powerful vocals (The Texas Bluesman himself) carries us on through the song, with the wave-like keys of Mr. John Thomas captivating us throughout.
                                <br></br>
                                <br></br>
                                <a href='https://www.youtube.com/watch?v=q5ERsCa72OU' target="_blank" rel="noreferrer" className='linkCa'>https://www.youtube.com/watch?v=q5ERsCa72OU</a>
                                <br></br>
                                <br></br>
                                A Gospel background is clearly visible (Audible, rather!) in Mr. Rayford's vocals, and while young, he even served as the choir director at Church. After his stint as a Marine, he entered contemporary music with his band "The Urban Gypsies", which played a mix of Soul, R&B and Funk, but primarily covers. More bands, a few ad-films (For which he made music) and many more covers later, Sugaray found himself as one of the world's leading Blues musicians, being nominated for two awards (The BB King Entertainer of the year and Contemporary Blues Male artist of the year) in 2017.
                                <a href='https://www.youtube.com/watch?v=wv8wLjKlRT8' target="_blank" rel="noreferrer" className='linkCa'>https://www.youtube.com/watch?v=wv8wLjKlRT8</a>
                                As he says in his song "Country Boy"</p>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <img src={img04} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                        </Col>
                    </Row>
                    <p className='newsParagraph italic'>"I'm a little country boy, running wild in the big old town"</p>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <img src={img004} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <p className='newsParagraph'>Mr. Rayford enthuses energy that makes his live performances absolutely enthralling. In one of his interviews to the Blues Blast Magazine</p>
                            <p className='newsParagraph'> <a href='http://www.bluesblastmagazine.com/featured-interview-sugaray-rayford/' target="_blank" rel="noreferrer" className='linkCa'>http://www.bluesblastmagazine.com/featured-interview-sugaray-rayford/</a> he says, "It must be real and virile every time we play, and that way people are getting a real party. I don't want people saying, 'He did this or that same show, the exact same show in the exact same place night after night after night that kills musicians' spirit".</p>
                        </Col>
                    </Row>
                    <p className='newsParagraph'>There is absolutely no doubt left in my mind after seeing his performances, that Mr. Rayford will single-handedly make every single soul at the Mehboob studios clap their hands in complete unison. I ask you to have one look at this video where he performs 'Death letter Blues' in front of a packed house at the Megeve Blues Festival, back in 2016 (https://www.youtube.com/watch?v=D-WaFglI1VA). Ten seconds into the video and you will understand the power his vocals possess.
                    <br></br>  <br></br>
You do not want to miss out the energy that the Texas Bluesman will bring along to Bandra, this year at the Mahindra Blues, 2019.
<br></br>  <br></br>
-Venkat Iyer</p>
                </div>
            </section>
        </div>
    )
}

export default SugarayPunch