import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Index from "./Components/Index";
import "../src/App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./Components/Footer";
import AboutUs from "./Components/AboutUs";
import scrollTop from './Assets/scrollToTop.svg'
import "react-image-gallery/styles/css/image-gallery.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import BluesArt from "./Components/BluesArt";
import ArtistInfo from "./Components/ArtistInfo";
import CulturalOutreach from "./Components/CulturalOutreach";
import Legacy from "./News/Legacy";
import MemphisMachine from "./News/MemphisMachine";
import SugarayPunch from "./News/SugarayPunch";
import WhiteBluesman from "./News/WhiteBluesman";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TermsOfUse from "./Components/TermsOfUse";
import Disclaimer from "./Components/Disclaimer";
import 'photoswipe/dist/photoswipe.css'
import PhotoGallery from "./Components/PhotoGallery";

function App() {
 
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <div className="bg">
      <div className="scroll-to-top">
        {isVisible &&
          <div onClick={scrollToTop}>
            <img className="scroll-top" src={scrollTop} alt='Go to top' />
          </div>}
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/artistinfo_schedule" element={<ArtistInfo />} />
            <Route path="/bluesart" element={<BluesArt />} />
            <Route path="/culturaloutreach" element={<CulturalOutreach />} />
            <Route path="/legacy" element={<Legacy />} />
            <Route path="/memphisMachine" element={<MemphisMachine />} />
            <Route path="/sugaraypunch" element={<SugarayPunch />} />
            <Route path="/whitebluesman" element={<WhiteBluesman />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/photogallery" element={<PhotoGallery />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

AOS.init();
export default App;
