import React from 'react';
import '../Styles/CulturalOutreach.css';
import coimg01 from "../Assets/Culturaloutreach/co01.png";
import coimg02 from "../Assets/Culturaloutreach/co02.png";
import coimg03 from "../Assets/Culturaloutreach/co03.png";
import coimg04 from "../Assets/Culturaloutreach/co04.png";
import coimg05 from "../Assets/Culturaloutreach/co05.png";

import { Carousel } from 'react-bootstrap';

function CulturalOutreach() {
    return (
        <div>
            <div className='bannerMainCO'>
                <Carousel fade interval={4000}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={coimg01}
                            alt="Cultural Outreach slide 1"
                        />
                        
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={coimg02}
                            alt="Cultural Outreach slide 2"
                        />

                        
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={coimg03}
                            alt="Cultural Outreach slide 3"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={coimg04}
                            alt="Cultural Outreach slide 4"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={coimg05}
                            alt="Cultural Outreach slide 5"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>
            <section className='culturalOutreachPage'>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='caSubHead'>About Cultural Outreach At Mahindra</p>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='ca_para'>The Mahindra Group, over the years, through its products and services, a deep sense of corporate responsibility and programs in cultural outreach, has enabled positive change in individuals and communities globally. Mahindra “Rise” is holding a mirror to our organization and articulating what we stand for.
                    <br></br>
                    <br></br>
                    Cultural outreach is about connecting with the communities we serve through specially created programs in the space of Art and Culture. Cultural Outreach is also about sparking conversations across different cultures through the vantage of a large global federation of companies.
                    <br></br>
                    Our cultural outreach programs are not mere sponsorships. Each initiative has a strategic purpose and creates a positive impact both for our brand as also for the art form we support. Each of these initiatives brings us closer to our stakeholders and helps strengthen our shared values.
                    <br></br>
                    <br></br>
                    <span className='bold'>Mahindra Excellence in Theatre Awards (META)</span>  has been momentous for several reasons. We are proud to have presented over 100 theatrical masterpieces from all over India in the national capital. Unknown theatre companies have presented on the same stage as well known ones. Learning and appreciation has been unilateral. A nomination for META is an honour, proudly flaunted by participants at subsequent enactments in their hometowns or elsewhere. Actors, directors, script writers and others in the theatre world feel a sense of having “made it” on being nominated for META. In a span of a week the audiences get a panoramic glimpse of the best of theatre from across the country. The META Awards are the only national level recognition in the field of Theatre. This is what we had set out to achieve. <a rel="noreferrer" href='http://metawards.com/' target='_blank' className='linkCa'>www.metawards.com</a>
                    <br></br>
                    <br></br>
                    The <span className='bold'>Mahindra Blues Festival</span> held at the iconic Mehboob Studios is the biggest Blues Festival in India. Over the years it has hosted legends like Buddy Guy, Taj Mahal, Walter Trout, Robert Randolf, Jonny Lang, Matt Scohfield, Popa Chubby, Shemekia Copeland, Ana Popovich, Dana Fuchs, John Lee Hooker Jr. and Jimmy Thackery. This festival celebrates the culture and tradition of the Blues which originated in the Mississippi Delta and gives the Mahindra brand a unique connect with the tractor customers of that region.
                    <br></br>
                    <br></br>
                    The <span className='bold'>Mahindra Sanatkada Lucknow Festival</span> is aimed to bring back Lucknowi Tehzeeb. Organised around a fair involving master craftsmen from the regions, the festival, has transformed into a celebration of craft, music, dance, cuisine and heritage. Spanning over 5 days, the festival enjoys the patronage of Lucknow families and families from surrounding towns and cities as well. Notable performances have included the likes of Shanti Hiranand, Shubha Mudgal, Indian Ocean, Dastan Goi, Parvathy Baul and Shruti Sadolikar. Held at the beautiful Baradari, a building specially created for culture by the last Nawab of Lucknow, Wajid Ali Shah, the intimate ambience lends itself wonderfully to relearn and appreciate the nuances of old world Nawaabi culture.
                    <br></br>
                    <br></br>
                    The newest addition this year is the Mahindra Kabira Festival to be held in Varanasi in November. Aimed to celebrate the secular teachings of the Poet Saint Kabir, the festival aims to be a beacon of love and hope in the Poet’s place of birth while reflecting the deeply rooted values of Equality and Dignity for all espoused by our Founders. <a rel="noreferrer" href='https://www.mahindrakabira.com/' target='_blank' className='linkCa'>www.mahindrakabira.com</a>
                </p>
                <div className='spaceCa'></div>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='caSubHead'>ABOUT MAHINDRA</p>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='ca_para'>The Mahindra Group focuses on enabling people to rise through solutions that power mobility, drive rural prosperity, enhance urban lifestyles and increase business efficiency.
                    <br></br>
                    <br></br>
                    A USD 17.8 billion multinational group based in Mumbai, India, Mahindra provides employment opportunities to over 200,000 people in over 100 countries. Mahindra operates in the key industries that drive economic growth, enjoying a leadership position in <a rel="noreferrer" href='https://www.mahindra.com/mahindra-services-and-businesses' target='_blank' className='linkCa'>tractors, utility vehicles, information technology, financial services</a> and <a rel="noreferrer" href='https://www.clubmahindra.com/' target='_blank' className='linkCa'>vacation ownership.</a> In addition, Mahindra enjoys a strong presence in the agribusiness, aerospace, components, consulting services, defence, energy, industrial equipment, logistics, <a rel="noreferrer" href='https://www.mahindra.com/mahindra-services-and-businesses' target='_blank' className='linkCa'>real estate</a> , retail, steel, <a rel="noreferrer" href='https://www.mahindra.com/mahindra-services-and-businesses' target='_blank' className='linkCa'>commercial vehicles</a> and <a rel="noreferrer" href='https://www.mahindra.com/mahindra-services-and-businesses' target='_blank' className='linkCa'>two wheeler</a> industries.
                    <br></br>
                    <br></br>
                    In 2015, Mahindra & Mahindra was recognized as the Best Company for CSR in India in a study by the Economic Times. In 2014, Mahindra featured on the Forbes Global 2000, a comprehensive listing of the world’s largest, most powerful public companies, as measured by revenue, profit, assets and market value. The Mahindra Group also received the Financial Times ‘Boldness in Business’ Award in the ‘Emerging Markets’ category in 2013.
                </p>
            </section>
        </div>
    )
}

export default CulturalOutreach