import React from 'react';
import '../Styles/NewsStyle.css';

function TermsOfUse() {
    return (
        <div>
            <section className='footerSubPages'>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='caSubHead'>Terms Of Use</p>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='ca_para'>By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.
                    <br></br>
                    <br></br>
                    <span className='headSub'>Permissions </span>
                    <br></br>
                    Permission is granted to temporarily download one copy of the materials (information or software) on Mahindra Blues Festival's web site for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                    <br></br><br></br>
                    1.Modify or copy the materials;
                    <br></br>
                    2.Use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
                    <br></br>
                    3.Attempt to decompile or reverse engineer any software contained on Mahindra Blues Festival's web site;
                    <br></br>
                    4.Remove any copyright or other proprietary notations from the materials;
                    <br></br>
                    5.Transfer the materials to another person or "mirror" the materials on any other server.

                    <br></br>
                    <br></br>
                    This license shall automatically terminate if you violate any of these restrictions and may be terminated by Mahindra Blues Festival at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                    <br></br><br></br>
                    <span className='headSub'>Limitations</span>
                    <br></br>
                    In no event shall Mahindra Blues Festival or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on Mahindra Blues Festival's Internet site, even if Mahindra Blues Festival or a Mahindra Blues Festival authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
                    <br></br><br></br>
                    <span className='headSub'>Site Terms of Use Modifications</span>
                    <br></br>
                    Mahindra Blues Festival may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
                </p>
            </section>
        </div>
    )
}

export default TermsOfUse