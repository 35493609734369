import React from 'react';
import '../Styles/BluesArt.css';
import { Row, Col } from 'react-bootstrap';
import BluesArtBanner from "../Assets/BluesArt/bluesartbanner.svg";
import mobilebanner from "../Assets/BluesArt/mobilebanner.svg";
import BA23 from "../Assets/BluesArt/ba23.png";
import BA23B from "../Assets/BluesArt/ba23B.png";
import BA20 from "../Assets/BluesArt/ba20.png";
import BA20B from "../Assets/BluesArt/ba20B.png";
import BA19 from "../Assets/BluesArt/ba19.png";
import BA19B from "../Assets/BluesArt/ba19B.png";
import BA18 from "../Assets/BluesArt/ba18.png";
import BA18B from "../Assets/BluesArt/ba18B.png";
import BA17 from "../Assets/BluesArt/ba17.png";
import BA17B from "../Assets/BluesArt/ba17B.png";
import BA16 from "../Assets/BluesArt/ba16.png";
import BA16B from "../Assets/BluesArt/ba16B.png";
import BA15 from "../Assets/BluesArt/ba15.png";
import BA15B from "../Assets/BluesArt/ba15B.png";
import BA14 from "../Assets/BluesArt/ba14.png";
import BA14B from "../Assets/BluesArt/ba14B.png";
import BA13 from "../Assets/BluesArt/ba13.png";
import BA13B from "../Assets/BluesArt/ba13B.png";
import BA12 from "../Assets/BluesArt/ba12.png";
import BA12B from "../Assets/BluesArt/ba12B.png";
import BA11 from "../Assets/BluesArt/ba11.png";
import BA11B from "../Assets/BluesArt/ba11B.png";
import ModalImage from "react-modal-image";


function BluesArt() {
    return (
        <div>
            <div className='bannerMainAbout'>
            </div>
            <section className='bluesArtMain'>
                <img data-aos="zoom-in"
                    data-aos-duration="3000" src={BluesArtBanner} className="bluesart_banner desktop" alt='blues art heading' />
                <img data-aos="zoom-in"
                    data-aos-duration="3000" src={mobilebanner} className="bluesart_banner mobile" alt='blues art heading' />

                <div className='content_blues_art'>
                <Row className='space_ba_info'>
                        <Col sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA23}
                                large={BA23B}
                                alt="Aashti Miller"
                            />;
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={7} xl={7}>
                            <div>
                                <h4 data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_name_ba'>Aashti Miller</h4>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_bio_ba'>Aashti Miller is an architect, illustrator and graphic designer. She was born and raised in Mumbai, and studied Architecture at Cornell University in Ithaca, New York. She is the sole force behind MillerInk, an illustration and graphic design studio. She constructs her drawings in an attempt to make sense of the unexpected collision of her two seemingly disparate worlds. As a result, her aesthetic and style have evolved into detailed drawings that tread the fine line between constructed and painted, digital and analog, two and three dimensional. Her work is heavily inspired by and explores spaces, places, and faces.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className='spaceSingleBa'>
                        <ModalImage hideDownload="true"
                                small={BA20}
                                large={BA20B}
                                alt="Blues Art 2020"
                            />;
                    </div>
                    <Row className='space_ba_info'>
                        <Col className='mobile' sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA19}
                                large={BA19B}
                                alt="Ashwam Shrikant Salokhe"
                            />;
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={7} xl={7}>
                            <div>
                                <h4 data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_name_ba'>Ashwam Shrikant Salokhe</h4>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_bio_ba'>The artist, Ashwam Shrikant Salokhe, finds inspiration for this year’s Mahindra Blues poster from local trains: “I’m a Mumbaikar, every day I travel by train. The way she runs on the tracks and honks always gives me a feeling of Blues music going on around me. You can really hear the drum beat when she runs and the saxophone trumpet when she honks, while people inside her talk, sing, laugh - that creates the blues, and this is all going inside me. In the poster you will see the silhouette of a person, and inside him a Mumbai train station platform, and on the railway track, a trumpet instead of a train.”</p>
                            </div>
                        </Col>
                        <Col className='desktop' sm={12} xs={12} md={12} lg={5} xl={5}>
                            <ModalImage hideDownload="true"
                                small={BA19}
                                large={BA19B}
                                alt="Ashwam Shrikant Salokhe"
                            />;
                        </Col>
                    </Row>
                    {/* 2 */}
                    <Row className='space_ba_info'>
                        <Col sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA18}
                                large={BA18B}
                                alt="Jenny Bhatt"
                            />;
                            
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={7} xl={7}>
                            <div>
                                <h4 data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_name_ba'>Jenny Bhatt</h4>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_bio_ba'>Jenny Bhatt has held 18 solo exhibitions to date. She has participated in several group exhibitions including those in New York, Sydney, London, Amsterdam and Berlin. Her work has been included in auctions, including those by Christie’s.
                                    <br></br>
                                    <br></br>
                                    She has written on art, humour and spirituality in publications including The Times of India group, DNA and Verve magazine. Her poems have been published in the literary journals including The Little Magazine and The Brown Critique. She has illustrated 9 children’s books and wrote a book on humorous art.</p>
                            </div>
                        </Col>
                    </Row>
                    {/* 3 */}
                    <Row className='space_ba_info'>
                        <Col className='mobile' sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA17}
                                large={BA17B}
                                alt="Rajmahamad Pathan"
                            />;
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={7} xl={7}>
                            <div>
                                <h4 data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_name_ba'>Rajmahamad Pathan</h4>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_bio_ba'>Artist Rajmahamad Pathan’s take on the Mahindra Blues shows a Koli fisherwoman with attitude. Against the backdrop of the sea link, Rajmahamad brings out the unmistakable Mumbai connect. He uses only microtip and ball point pens to fill in the colours, shades and textures making it a slow laborious process.</p>
                            </div>
                        </Col>
                        <Col className='desktop' sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA17}
                                large={BA17B}
                                alt="Rajmahamad Pathan"
                            />;
                        </Col>
                    </Row>
                    {/* 4 */}
                    <Row className='space_ba_info'>
                        <Col sm={12} xs={12} md={12} lg={7} xl={7}>
                        <ModalImage hideDownload="true"
                                small={BA16}
                                large={BA16B}
                                alt="Aditi Singh"
                            />;
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={5} xl={5}>
                            <div>
                                <h4 data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_name_ba'>Aditi Singh</h4>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_bio_ba'>
                                    Moving through a blues-soaked world,<br></br>
                                    A planet where roses touch a grief<br></br>
                                    and scarlet meets a sea.<br></br>

                                    Moving through a blues-soaked world<br></br>
                                    at a plantation in a song,<br></br>
                                    In the attention of a heart, in the forest of a memory<br></br>
                                    In the glittering labour of hands,<br></br>
                                    As narrative, as ballad,<br></br>
                                    As a chant, as a string<br></br>
                                    As a shout<br></br>
                                    In the dark.<br></br>

                                    Moving through a blues-soaked world,<br></br>
                                    Where among days,<br></br>
                                    Living only the force<br></br>
                                    of days.<br></br>
                                    Most lyrical,<br></br>
                                    Most difficult.</p>
                            </div>
                        </Col>
                    </Row>
                    {/* 5 */}
                    <Row className='space_ba_info'>
                        <Col className='mobile' sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA15}
                                large={BA15B}
                                alt="Sharmistha Ray"
                            />;
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={7} xl={7}>
                            <div>
                                <h4 data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_name_ba'>Sharmistha Ray</h4>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_bio_ba'>Sharmistha Ray's poster for the Mahindra Blues Festival is a synthesis of art and design concepts inspired by Mumbai and music. Using the visual of the ocean dotted with coloured fishing boats - a unique signature of Mumbai's urban landscape - Ray has created a dynamic visual adapted from an original painting to complement the spirit of a Blues festival in Mumbai. Painting in the abstract, for Ray, also has close links with music. The painter's palette knife is akin to a conductor's baton: colour, temperature, gesture, mood, texture and myriad layers crescendo towards a total symphony. The semi-transparent font, which was created with an urban skyline stencil and a gritty texture to reflect Mumbai's urban grit, was superimposed on the visual to mimic a contemporary light projection upon the water. <br></br>
                                    Ray is an artist, writer and curator living in Mumbai and New York. In 2014, Ray was also commissioned by the Berklee College of Music to design a special poster for the historic event "A.R. Rahman Meets Berklee" at Boston Symphony Orchestra, USA. She is a TED Fellow and has won numerous accolades including a Montblanc Young Artist World Patronage Award. Her works are a part of private and corporate collections all around the world.</p>
                            </div>
                        </Col>
                        <Col className='desktop' sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA15}
                                large={BA15B}
                                alt="Sharmistha Ray"
                            />;
                        </Col>
                    </Row>
                    {/* 6 */}
                    <Row className='space_ba_info'>
                        <Col sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA14}
                                large={BA14B}
                                alt="Brinda Miller"
                            />;
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={7} xl={7}>
                            <div>
                                <h4 data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_name_ba'>Brinda Miller</h4>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_bio_ba'>The 'havaldar' blowing a whistle; the 'horn ok please'; the temple bell; the flute seller and various vehicles honking; the crow cawing - Renowned Mumbai based artist Brinda Miller presents an experiment in mixed media, creating a montage of images related to the sounds of Mumbai. Her vision brings to life the spirit of Blues music in the everyday sounds of the cosmopolitan city that has become the home of this genre in India. Exclusive, limited edition prints signed by Brinda will be available for purchase at the Festival venue.</p>
                            </div>
                        </Col>
                    </Row>
                    {/* 7 */}
                    <Row className='space_ba_info'>
                        <Col className='mobile' sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA13}
                                large={BA13B}
                                alt="Jehangir Jani"
                            />;
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={7} xl={7}>
                            <div>
                                <h4 data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_name_ba'>Jehangir Jani</h4>
                                <p data-aos="fade-up"
                                    data-aos-duration="2000" className='artist_bio_ba'>Noted Mumbai based artist Jehangir Jani <a className='link_ba' href='www.jehangirjani.com'>(www.jehangirjani.com)</a>  has beautifully brought out the connect between the Blues and the city of Mumbai in these Festival Posters specially created for the Mahindra Blues Festival. He has used popular Mumbai icons along with musical instruments most commonly used in creating the Blues. Exclusive, limited edition prints, signed by Jehangir, will be available for purchase at the Festival venue.</p>
                            </div>
                        </Col>
                        <Col className='desktop' sm={12} xs={12} md={12} lg={5} xl={5}>
                        <ModalImage hideDownload="true"
                                small={BA13}
                                large={BA13B}
                                alt="Jehangir Jani"
                            />
                        </Col>
                    </Row>
                    {/* 8 */}
                    <Row className='space_ba_info'>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                        <ModalImage hideDownload="true"
                                small={BA12}
                                large={BA12B}
                                alt="Blues Art 2012"
                            />
                            
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                        <ModalImage hideDownload="true"
                                small={BA11}
                                large={BA11B}
                                alt="Blues Art 2011"
                            />
                        </Col>
                    </Row>
                </div>
            </section>
        </div>
    )
}

export default BluesArt