import React from "react";
import "../Styles/ArtistInfo.css";
import { Row, Col } from "react-bootstrap";




import bethhart from "../Assets/lineup/new/bh.png";
import dana from "../Assets/lineup/new/df.png";
import mamatips from "../Assets/lineup/new/mtatmb.png";
import sheryl from "../Assets/lineup/new/sy.png";
import vanessa from "../Assets/lineup/new/vc.png";
import samantha from "../Assets/lineup/new/sf.png";

// 
function myFunction() {
  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  if (dots.style.display === "none") {
    dots.style.display = "inline";
    btnText.innerHTML = "Read more"; 
    moreText.style.display = "none";
  } else {
    dots.style.display = "none";
    btnText.innerHTML = "Read less"; 
    moreText.style.display = "inline";
  }
}
// 2
function myFunction1() {
  var dots1 = document.getElementById("dots1");
  var moreText1 = document.getElementById("more1");
  var btnText1 = document.getElementById("myBtn1");

  if (dots1.style.display === "none") {
    dots1.style.display = "inline";
    btnText1.innerHTML = "Read more"; 
    moreText1.style.display = "none";
  } else {
    dots1.style.display = "none";
    btnText1.innerHTML = "Read less"; 
    moreText1.style.display = "inline";
  }
}
// 3
function myFunction2() {
  var dots2 = document.getElementById("dots2");
  var moreText2 = document.getElementById("more2");
  var btnText2 = document.getElementById("myBtn2");

  if (dots2.style.display === "none") {
    dots2.style.display = "inline";
    btnText2.innerHTML = "Read more"; 
    moreText2.style.display = "none";
  } else {
    dots2.style.display = "none";
    btnText2.innerHTML = "Read less"; 
    moreText2.style.display = "inline";
  }
}
// 4
function myFunction3() {
  var dots3 = document.getElementById("dots3");
  var moreText3 = document.getElementById("more3");
  var btnText3 = document.getElementById("myBtn3");

  if (dots3.style.display === "none") {
    dots3.style.display = "inline";
    btnText3.innerHTML = "Read more"; 
    moreText3.style.display = "none";
  } else {
    dots3.style.display = "none";
    btnText3.innerHTML = "Read less"; 
    moreText3.style.display = "inline";
  }
}




function ArtistInfo() {
  return (
    <div>
      <section className="artist_info_main">
        <div className="mbspace">
          <h3
            data-aos="fade-up"
            data-aos-easing="ease-in"
            data-aos-duration="2000"
            className="galleryMainHead"
          >
            ARTIST LINEUP
          </h3>
          {/* <p className='comingSoonTxtDesktopba'>Coming Soon</p> */}
        </div>


         <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle"
        >
          <Col sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={bethhart}
                alt="beth hart"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Beth Hart</p>
            <p className="aboutartist">
            Beth Hart is as real as it gets. In a music industry full of glossy production and airbrushed photoshoots, this is one artist who throws down her cards, shares her darkest secrets and invites you to join her for the ride. 
            <br></br><br></br>
            New fans might know Beth as the all-conquering global icon, dubbed "extraordinary" by The Times and "daring, brooding and angry" by The Guardian. But to understand her rollercoaster backstory, you need only read the War In My Mind lyric sheet.  
            <br></br><br></br>
            Hart is recognized as one of her generation's most talented voices. She has sold out tours worldwide, including performances at historic venues such as Nashville's Ryman Auditorium, London's Royal Albert Hall, and the Ziggo Dome in Amsterdam. She's topped the Billboard charts six times, gone double platinum and had a string of Top 10 charting albums across Europe. 
            <span id="dots">...</span><span id="more">
            The Grammy-nominated powerhouse vocalist Beth Hart took on one of her most profound undertakings to date by channeling the legendary voice of Robert Plant on A Tribute To Led Zeppelin. The nine-track album highlights the incredible musical spectrum that Led Zeppelin operated in.  
            <br></br><br></br>
            All that was left was the final piece of the puzzle... the voice. Things clicked into place when Cavallo was producing Hart's previous album, War In My Mind (2019), and she did an impromptu version of "Whole Lotta Love" in the studio. He later asked about doing a whole record. Beth said, "I'm not doing this whole album. To do Zeppelin, you've got to be pissed off to hit that right. I can't go there; I've worked years to put my rage away. “She continues, "then the pandemic and all the things around it hit. So now I'm pissed off. I called my manager and said, have Rob and Doug send me all the music because I am ready to do this. “If you were to rewind, you could say the story for a Led Zeppelin album started further back; in May 2004 Hart prowled the stage at the Paradiso in Amsterdam for an incendiary performance of "Whole Lotta Love". The song became a semi-regular fixture in her setlist over the years, including a memorable performance with Slash.  
            <br></br><br></br>
            At 50–and proud of it–Beth is basking in a golden period. The success of 2019's War In My Mind album has garnered yet more critical acclaim, growing sales and sold-out shows. But as the singer reminds us, her life has always moved in cycles–"things get good then go to crap, get good then go to crap"–and it's in these extremes that many of her best songs are born. </span>
            <button onClick={myFunction} className="stylereadmrebtn" id="myBtn">Read more</button>
            </p>
            
          </Col>
        </Row>

        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle spacearl"
        >
          <Col className="mobile" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={dana}
                alt="dana"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Dana Fuchs</p>
            < p className = "aboutartist" >
              American Dana Fuchs is one of the most coveted blues rock stars in the world today.She is a phenomenon of a singer whose
            hypnotic voice and presence has led critics to compare her to
            Janis Joplin, Otis Redding, Tina Turner & Mick Jagger.Dana
            Fuchs is rock, soul, gospel, and blues in a wonderful mix,
              delivered with raw empathy and energy that has captured large
            audiences all over the world.She can be both deeply sensitive,
              vulnerable, and gentle on stage,
              while at the same time she can
            be a powerful and intense force of nature.Her records receive
            fantastic reviews, and she has been in the top 10 on the world 's
            most important sales list - Billboard in the USA.In addition to an
            impressive music career, she is also a widely known actress,
            most notably starring as Sadie in the cult film Across the
            Universe, which was nominated
            for Best Picture at the Golden
            Globe Awards.Dana Fuchs’ new album, Borrowed Time is
            available everywhere now via Ruf Records(2022). 
            </p>
          </Col>
          <Col className="desktop" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={dana}
                alt="dana"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
        </Row>

        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle"
        >
          <Col sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={mamatips}
                alt="Mama Tips and The Mama's Bois "
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Tipriti Kharbangar</p>
            <p className="aboutartist">
            Mama Tips and the Mamas Bois, consist of the legendary vocalist, Tipriti Kharbangar from the band Soulmate on vocals and rhythm guitars, backed by the band consisting of Lal on lead guitars, Bame "Fingaz" Ryntathiang on keys, Deep Limbo on bass, and Sunny Manbha on drums. They have been playing a handful of memorable shows in Shillong, Guwahati, Itanagar, Aizawl in the span of a few months.  They play a wide range of genres from Blues to Soul to RnB. Backed up by a powerful band, their sets of originals and covers led by the beautiful voice and dynamic energy of Lil Mama Tips and LB Kings’ sweet tone and amazing guitar playing will surely hit the right notes with the audiences. 
            </p>
          </Col>
        </Row>

        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle spacearl"
        >
          <Col className="mobile" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={sheryl}
                alt="sheryl"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Sheryl Youngblood</p>
            <p className="aboutartist">
            Growing up in a musically inclined family with her mother having her own female group “The Royal Harmonetts” and being a pianist and vocalist in the church, Sheryl was born into music. 
            <br></br>
            2015 Chicago Blues Hall Of Fame Inductee. Sheryl began singing and playing various instruments in the church, in her teen years Sheryl began her own female group in her church (Mt. Zion Baptist Church) called “Sisters” in which she wrote several of their songs and also wrote songs for the National Baptist State Convention, (unfortunately those songs were not recorded) soon after Sheryl auditioned for and was immediately excepted into the world renowned gospel recording group “Rev. Milton Brunson and the Thompson Community Singers of Chicago” affectionately called “The Tommies” while singing with “The Tommies” the group became a Grammy and Stella Award winning group. During that time Sheryl began playing drums and or singing with various recording artist such as Otis Clay, Artie “BLUES BOY” White, Bobby “Slim” James, “KoKo Taylor and “The KoKo Taylor Blues Foundation”, Nellie “Tiger” Travis, Willie Rogers, 3x Hall of Famer Billy Shelton, 3x Stella Award winner “Darious Brooks”,
            <span id="dots1">...</span><span id="more1">

             Hall of Fame Group “The Spaniels, “Raven” Grammy and Emmy Award winner Jennifer Hudson, Yolanda Adams, Daryl Coley, LeAnn Faine, Stormy Weather, she has opened for The Whispers, Buddy Guy, Lakeside, Chante Moore, Reheem De’Vaughn, Kindred The Family Soul, Denise LaSalle, Bobby Rush, John Primer, Maurice John Vaughn and the list goes on in 1995 Sheryl Formed “SAYYES!” band and an all female band Ultimately Blessed a.k.a. “UB”  SAY YES! And UB performed at many different venue’s and put on yearly benefit concerts for SWWR (Batter and abused Women and Children Facility) Cancer; Sickle cell Anemia; Diabetes; Prostate Cancer and more. SAYYES! the band continues to play a variety of music, a variety of venues and for a variety of events SAYYES! has opened for many artist as mentioned on the SAYYES! page of this website www.sayyeslive.com the band continues on, making and playing music. Sheryl has been on Radio, Television, in multiple Blues magazines and in digital media. 
            
            <br></br>
            <br></br>
            Sheryl started her own Blues band Sheryl Youngblood Blues Band in 2013 A Powerful Vocalist; Drummer; Songwriter; Bandleader; Entertainer. works regularly and travels extensively. Performing at such places as The world renown BLUE CHICAGO as a regular artist of the club as well as playing at many venues in and out of the United State with bookings at Buddy Guys Legend, Rosa’s Lounge, House of Blues Main Stage, Crossroads Stage and Foundation Room, Kingston Mines, B.L.U.E.S on Halsted, Taste of Chicago, Taste of East Chicago, Blue Chip Casino, Majestic Star Casino, Hardrock Casino MWI, Muckleshoot Casino Seattle Washington, Ameristar Casino Jackson Mississippi, Country Club Hills Theater , Blues On White Edmonton Canada, Blues Can Calgary Canada, and many more venues, keeping the audience involved and energized with a TRUE show. Sheryl also sings and performs with world renowned group, Mississippi Heat 
            <br></br>
            <br></br>
            A/V (Audio/Visual): Attended school of Can TV: Videography; Video recording and editing and is now certified as a videography and Audio technician Working in the music business has afforded Sheryl many opportunities for hands on training and teaching of Audio, Video, lighting, spot lighting and as a full on stagehand with Hands R Us Staging for multiple venues: Country club hills Theater; House of Hope special events to name just a few. 
            <br></br>
            <br></br>
            Discography: Stranger In My Bed, Live at House of Blues with her rearrangement of At Last by Etta James and other songs you will love to listen to and soon hear in the Blues and R&B sector. 

            Sheryl’s mission is to touch everyone possible musically even if only for an hour or two on stage making someone feel better then they did when they arrived to the event! </span>
            <button onClick={myFunction1} className="stylereadmrebtn" id="myBtn1">Read more</button>
            </p>
          </Col>
          <Col className="desktop" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={sheryl}
                alt="Sheryl Youngblood"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
        </Row>
        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle"
        >
          <Col sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={vanessa}
                alt="vanessa"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Vanessa Collier</p>
            <p className="aboutartist">
            “There’s a young lady [Vanessa Collier] came onstage with me, I forget where I was, but she’s playing an alto saxophone, and man, she was amazing.” 
            <br></br>
            <br></br>
            Those are the words of Buddy Guy in a recent issue of American Blues Scene, describing an impromptu performance with Vanessa Collier on the Legendary Rhythm & Blues Cruise. If you haven’t been fortunate enough to meet Vanessa and witness one of her head-turning, fiery, and passionate performances, you should definitely make sure you do. As a master musician and multi-instrumentalist, Vanessa Collier, weaves funk, soul, rock, and blues into every powerful performance and she is downright impressive. With soulful vocals, searing saxophone, and witty songwriting, Vanessa is blazing a trail, racking up an impressive arsenal of honours, and has already singled herself out as an artist of distinction and one we would all do well to watch. 
            <br></br>
            <br></br>
            It’s not simply the accolades she has accumulated so far, although they’ve been many -- two Blues Music Awards for “Horn Player of the Year”, and a third BMA for “Contemporary Blues Female Artist of the Year”, eight Blues Music Award Nominations (BMAs), a Blues Blast Award nomination, the Jammingest Pro Award bestowed by the Legendary Rhythm & Blues Cruise, 
            <span id="dots2">...</span><span id="more2">
            First Place for lyrics in the International Songwriting Competition, Best of 2014 Blues Breaker on Dan Aykroyd’s BluesMobile -- it’s the fact that she has accumulated all these honours even while her career is still in its infancy. A 2013 graduate of the prestigious Berklee College of Music, she’s toured nationally and internationally, released four critically acclaimed albums (2014’s Heart, Soul & Saxophone, 2017’s Meeting My Shadow, and 2018’s Honey Up, 2020’s Heart On The Line. Honey Up spent 9 weeks atop the Billboard Blues Album Charts Top 15, 3 months on the Living Blues Charts at #10 and #23, and continues to be spun on Sirius XM’s B.B. King’s Bluesville radio station!  
              <br></br>
              <br></br>
            It’s not surprising that the press has been quick to commend her efforts as well. Blues Blast Magazine affirms, “Vanessa Collier is a fresh face on the American blues scene, and in addition to her soulful vocals, she brings a mighty sax to the table...” Blues Music Magazine proclaims, “Collier is a fresh face who comes to the blues stage carrying her reed instruments, a dual degree from Berklee College of Music in Boston, a major label debut, almost two years of touring with Joe Louis Walker, and a mature musical vision…Collier commands center stage with her vocals, soloing, and stage presence.” Midwest Record exclaims, “This bluesy singing sax player knows how to bring the slinking funk to her captivating, award winning sound that has echoes in young Bonnie Raitt/Maria Muldaur vocal sounds…Killer stuff on every level, this sounds like one of the reasons you first became a music fan. Killer stuff.” and AXS.Com declares that she “might as well go ahead and add another shelf to her trophy case because clearly, she’s going to need it. With a voice that often recalls that of blues-rock stalwart Bonnie Raitt, Collier turns up the heat...” 
            <br></br>
            <br></br>
            Having first picked up the saxophone at the age of nine, Collier embarked on her road to recognition at Berklee, earning dual degrees in Performance and Music Production & Engineering. While still completing her degree, Collier landed a position touring with Joe Louis Walker for a year and a half before Joe encouraged her to strike out on her own. And strike out she did. 
            <br></br>
            <br></br>
            These days, Collier spends much of her time on the road, performing at some of the most prestigious music festivals in the world. Her talents have taken her to the Blues Music Awards Show where, in the words of the Blues Foundation, Vanessa “blew the doors off the Blues Music Awards!”; Ottawa Blues Festival, where she earned two front page newspaper photos and rave reviews after her foot stomping debut there; twice on the Legendary Rhythm and Blues Cruise, where she earned the Jammingest Pro Award and was a featured artist with Tommy Castro’s Blues Review; Briggs Farm Festival, where she was the “most popular Briggs Farm act in 2017” (Elmore Magazine), and the list continues. In 2017, Vanessa was also a featured artist in three major tours across Europe as part of Ruf’s 2017 Blues Caravan. 
            <br></br>
            <br></br>
            Vanessa has won 3 Blues Music Awards - two for “Horn Player of the Year” in 2019 and 2020 and one in 2022 for “Contemporary Blues Female Artist of the Year”!! She has been nominated for eight Blues Music Awards since 2017, which puts her in elite company with artists such as Beth Hart, Samantha Fish, Shemekia Copeland, Ruthie Foster, Trombone Shorty, Al Basile, and Jimmy Carpenter. 
            <br></br>
            <br></br>
            As for Collier, she says she’s happy performing on stages across the world, hoping that she can make a difference with fans and followers along the way. “I am driven to do this because I find it a total form of expression and connection,” she explains. “I love connecting with an audience and feeling their energy and I hope to inspire people to follow their dreams and passions, to find what brings them joy, and then to pursue it doggedly.” Vanessa also takes that message to young people as she runs clinics across the country and is active with Blues in the Schools programs. At a recent Blues in the School program, Vanessa invited a stage full of elementary and middle school aged kids up to dance around her while she performed a blues shuffle. Watching the joy on the faces of Vanessa, the kids, and everyone in the auditorium, it is no wonder why Vanessa has become awe-inspiring to all who meet her. On top of talent and hard work, Vanessa shares an incredible heart and a strong purpose. To borrow from the words of an article in Blues Blast Magazine, “Vanessa is an important part of the future of the blues, and it would be cool to support her journey. </span>
            <button onClick={myFunction2} className="stylereadmrebtn" id="myBtn2">Read more</button>
            </p>
          </Col>
        </Row>
        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle spacearl"
        >
          <Col className="mobile" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={samantha}
                alt="samantha"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Samantha Fish</p>
            <p className="aboutartist">
            Over the course of her career as an award-winning artist, singer/songwriter/guitarist Samantha Fish has brought extraordinary power to her self-expression, capturing her inner world in combustible riffs, visceral rhythms, and spine-tingling vocal work. On her new album Faster, she joins forces with superproducer Martin Kierszenbaum (Lady Gaga, Sting, Sheryl Crow) and imbues even more intensity into her electrifying brand of blues/rock-and-roll. With Fish accompanied by legendary drummer Josh Freese (Guns N’ Roses, Nine Inch Nails, The Replacements) and bassist Diego Navaira of The Last Bandoleros, the result is a singular body of work both irresistibly galvanizing and emotionally raw. 
            <br></br>
            <br></br>
            The follow-up to 2019’s Kill or Be Kind (Fish’s Rounder Records debut), Faster came to life at the famed Village Studios in Los Angeles, where she and Kierszenbaum uncovered new possibilities in her captivating sound. “Kansas City played a major part in bringing us together: I was born and raised in KC and Martin has some familial ties. Shortly after being introduced last year, we had a conversation about making an album,” she recalls. “His track record was perfect for what I wanted to do with this album, which was to expand into different genres while retaining the roots I’d built in the blues world.” 
            <span id="dots3">...</span><span id="more3">
            Revealing her affinity for North Mississippi blues heroes like R.L. Burnside and wildly inventive iconoclasts like Prince, the album ultimately embodies an unbridled energy true to its emotional core. “The whole record has a theme of taking charge and taking the reins, in a relationship or in life in general,” says Fish. “I really thought that after 2020 I’d end up with a really dismal, bleak album, but instead, we came up with something that’s fun and sexy and so empowering.” 
            <br></br>
            <br></br>
            Faster opens on its spellbinding title track, a fiercely stomping number whose vocal hook states her intentions to “make your heart beat faster.” Immediately making good on that promise, Fish next unleashes the restless urgency of “All Ice No Whiskey,” a pop-perfect powerhouse she considers something of a dare. “‘All Ice No Whiskey’ is a way of telling someone they don’t have any of that substance I’m looking for—sort of like, ‘Come back when you’ve got something interesting for me,’” Fish says. That defiant spirit also infuses “Better Be Lonely,” a fantastically loose and freewheeling track graced with a frenetic guitar solo. “That song’s about putting someone on ice, where you’re telling them: ‘I don’t want you right now, but when I am ready to have you, you better be there,” says Fish. Another bold statement of self-possession, “Twisted Ambition” brings mercurial rhythms and jagged guitar work to Fish’s refusal to let others define her. “It’s about flipping the roles of power—taking control and confronting a world that tries to put you in your place,” she notes. 
            <br></br>
            <br></br>
            While Faster never fails to showcase the gritty vitality of Fish’s musicianship, much of the album journeys into elegantly eclectic sonic terrain. One of Faster’s most vulnerable moments, “Crowd Control” unfolds in delicate beats and shimmering keyboard tones, forming a dreamy backdrop to Fish’s self-reflection. “It’s about confronting your demons – separating the version of yourself that you portray to the world from who you actually are. At its core, it’s about expressing vulnerability,” Fish explains. “When I wrote it, it felt like a true rootsy, Americana song. Martin added keys and modern synth textures that really brought it back around to this plaintive mood.” Featuring a guest spot from rapper/singer/songwriter Tech N9ne, “Loud” drifts from doo-wop reverie to guitar-fueled frenzy, riding that tension to glorious effect. “Tech N9ne is by far one of the biggest artists to come out of KC, and one of the biggest self-made artists in the world,” says Fish. “He was perfect for ‘Loud,’ which is a song about speaking your truth as loud as you can. It’s about saying to the person you’re with: ‘I want you, but I want you ugliness and all. So don’t ever be afraid to speak your mind and speak your heart.’” And on “Hypnotic,” Fish lays down a mesmerizing piece of R&B-pop, telegraphing unfettered desire in her seductive vocal delivery and lushly textured grooves. “‘Hypnotic’ is about putting somebody under a spell,” she says. “There’ve been times in my life when I haven’t felt all that in control in a relationship, and this song was my chance to become that person.” 
            <br></br>
            <br></br>
            For the final track to Faster, Fish selected the album’s most tender song, a gorgeously stark and slow-burning ballad called “All the Words.” “It’s about letting a relationship go because that’s what’s best for everyone, even though it’s a horribly painful experience,” says Fish. “When we recorded it, it was just me on guitar, Diego on bass, Martin playing the grand piano. It’s a song I hold very close, and it felt right to make it as raw as possible.” 
            <br></br>
            <br></br>
            Throughout Faster, Fish threads her songwriting with the kind of nuanced storytelling and ultravivid detail that comes from carefully honing her craft. “Because we usually tour so much, most of my albums have been written in hotels between shows,” she says. “This was the first time I’ve ever had the opportunity to just sit in one place, and pour everything that was happening around me into songs.” Growing up in Missouri, Fish first found her love of songwriting in her late teens, mining inspiration from the likes of Tom Waits and Leonard Cohen. Having taken up guitar at age 15, she soon began seeking out gigs by cold-calling countless local bars. “I used to open the phone book and call up every place in Kansas City, even if they didn’t have live shows,” she remembers. “I had maybe a three percent success rate, but eventually I started filling up my calendar—if you put on a good enough show, word of mouth gets around.” Over the years, Fish has maintained her reputation as a phenomenal live act and repeatedly turned out critically acclaimed albums, in addition to earning an ever-growing number of accolades. 
            <br></br>
            <br></br>
            For Fish, one of the greatest joys of making music is the powerful exchange of energy at her incendiary live shows. “I fell in love with music from going to shows, and I know how cathartic it can be. It heals your heart,” she says. “Anytime I play live, I just want to want to make people forget about everything else in the world and feel that same joy that I feel on stage.” And in the process of creating Faster, Fish experienced a similar exhilaration—a sustained head rush that’s entirely palpable in every track on the album. “There’s such a transformation that can happen in the studio when you really own that freedom to be creative,” she says. “I feel so charged up in those moments, like I can be whoever I want to be. It’s just me and these incredible musicians trying to make a piece of art that speaks for itself and contributes something new to the world. It’s never hard to feel inspired or empowered when that’s the mission.” 
            </span>
            <button onClick={myFunction3} className="stylereadmrebtn" id="myBtn3">Read more</button>
            </p>
          </Col>
          <Col className="desktop" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={samantha}
                alt="samantha"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default ArtistInfo;
