import React from 'react';
import '../Styles/NewsStyle.css';
import img05 from "../Assets/Home/news/img05.png";
import img005 from "../Assets/Home/news/img005.jpeg";
import img0051 from "../Assets/Home/news/img0051.jpeg";
import vptb from "../Assets/Home/news/vptb.svg";
import { Row, Col } from 'react-bootstrap';


function WhiteBluesman() {
    return (
        <div>
            <section className='newsContainerMain'>
                <div>
                    <div className='center'>
                        <img src={vptb} className="posterNews" alt='venky pens the blue poster' />
                    </div>

                    <p data-aos="fade-up"
                        data-aos-duration="2000" className='caSubHead'>THE DAWN OF THE 'WHITE BLUESMAN'                        </p>
                    <p className='newsParagraph'>The 74 year-old legend bends down, ever so slightly, displaces the harmonica off his lips, and then with a slight shake of his head, utters the words "Is there no mercy in this land?" I am utterly devastated.</p>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <p className='newsParagraph'>Mr. Musselwhite, also known as 'The White Bluesman', has the capacity to gently destroy your soul and then bring it back to life. Born in Mississippi to a family ensconced in music, it did not take him long to enter the world of the Blues. Growing up with legends such as Muddy Waters, Buddy Guy and John Lee Hooker (With whom he developed a very close friendship), he released his first album in 1966 titled 'Stand back! Here comes Charley Musselwhite's Southside band', an instant hit that went to receive a legendary status. One song from this album that jolts you out of the blue (Or rather plunges you deep within) is 'Sad day'
                                <a href='https://www.youtube.com/watch?v=7fzFuzLfhVI' target="_blank" rel="noreferrer" className='linkCa'>https://www.youtube.com/watch?v=7fzFuzLfhVI</a>, where the organ lulls you into a false sense of drowning, the harmonica pulling you out just before your head begins to sink underwater.</p>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <img src={img05} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                        </Col>
                    </Row>

                    <p className='newsParagraph'>'Memphis Charlie' released 20 albums since, and has also been part of a few movies. But it took him 11 nominations before he finally won the Grammy in 2014 for his album 'Get up!', which he co-created with the American songwriter Ben Harper.</p>
                    <p className='newsParagraph italic'>"I don't want to go my way, If you're not going my way So I guess that means, I'll be going your way"</p>
                    <br></br>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <img src={img005} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <p className='newsParagraph'>The best piece in the album in my view, 'We can't end this way" <a href='https://www.youtube.com/watch?v=cWay-exGNZM' target="_blank" rel="noreferrer" className='linkCa'>https://www.youtube.com/watch?v=cWay-exGNZM</a> waltzes its way through a carefully constructed play of hands, right into our souls. Yet another piece that shows you his capability to both destroy and save us. The rest of the album (Chicago and Delta blues) is a gem too, with Ben Harper flooring us with his Weissenborn lap slide at numerous occasions and Mr. Musselwhite hypnotizing us with his vocals, phrasing us into rapt attention.
                                <br></br>  <br></br>
                                The first job Mr. Musselwhite landed while in Chicago was that of a driver for an exterminator. He recalls (In an interview for the Bluesblast magazine </p>
                        </Col>
                    </Row>
                    <p className='newsParagraph'> <a href='http://www.bluesblastmagazine.com/featured-interview-charlie-musselwhite/' target="_blank" rel="noreferrer" className='linkCa'>http://www.bluesblastmagazine.com/featured-interview-charlie-musselwhite/</a> how while driving he would encounter numerous posters of Muddy waters across town, thus beginning his journey into the Chicago club scene. After his first break, where he stunned a 4th of July audience (who had come expecting some 'folk' singing) with his mesmerizing Harmonica, there was no looking back. Soon, he began his well-spoken about friendship with the legendary John Lee Hooker and both of them waltzed through the 70s and 80s, dominating the Blues scene.

</p>
                    <br></br>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <p className='newsParagraph bold'>Ouch</p>
                            <p className='newsParagraph'>The Harmonica is going to ring loud this year at the Mahindra Blues Festival, with notes stretching from Bandstand to Bandra Talao. He might be 74, but Mr. Musselwhite is at his peak currently and thus one would definitely not want to miss out on this truly special performance, this February at Mehboob studios.
                                <br></br>
                                <br></br>
                                -Venkat Iyer</p>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <img src={img0051} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                        </Col>

                    </Row>
                </div>
            </section>
        </div>
    )
}

export default WhiteBluesman