import React from 'react';
import '../Styles/NewsStyle.css';

function PrivacyPolicy() {
    return (
        <div>
            <section className='footerSubPages'>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='caSubHead'>Privacy Policy</p>
                <p data-aos="fade-up"
                    data-aos-duration="2000" className='ca_para'>This Privacy Policy governs the manner in which Mahindra Blues Festival collects, uses, maintains and discloses information collected from users (each, a "User") of the www.mahindrablues.com website ("Site"). This privacy policy applies to the Site and all products and services offered by Mahindra Blues Festival.
                    <br></br>
                    <br></br>
                    <span className='headSub'>Web browser cookies</span>
                    <br></br>
                    Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
                    <br></br><br></br>
                    Mahindra Blues Festival may collect and use Users personal information for the following purposes:
                    <br></br><br></br>
                    1.To improve our Site : We may use feedback you provide to improve our products and services.<br></br>
                    2.To send periodic emails : We may use the email address to respond to their inquiries, questions, and/or other requests.
                    <br></br>
                    <br></br>
                    <span className='headSub'>How we protect your information</span>
                    <br></br>
                    We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.
                    <br></br><br></br>
                    <span className='headSub'>Sharing your personal information</span>
                    <br></br>
                    We do not sell, trade, or rent Users personal identification information to others.
                    <br></br><br></br>
                    <span className='headSub'>Third party websites</span>
                    <br></br>
                    Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.
                    <br></br><br></br>
                    <span className='headSub'>Changes to this privacy policy</span>
                    <br></br>
                    Mahindra Blues Festival has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
                    <br></br><br></br>
                    <span className='headSub'>Your acceptance of these terms</span>
                    <br></br>
                    By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
                    <br></br><br></br>
                    <span className='headSub'>Contacting us</span>
                    <br></br>
                    If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at: <a href="mailto:mahindrablues@gmail.com" className='footerP'>mahindrablues@gmail.com</a> 
                    <br></br><br></br>
                    This document was last updated on December 28, 2012
                </p>
            </section>
        </div>
    )
}

export default PrivacyPolicy