import React from 'react';
import '../Styles/NewsStyle.css';
import img03 from "../Assets/Home/news/img03.png";
import img003 from "../Assets/Home/news/img003.jpeg";
import img0031 from "../Assets/Home/news/img0031.jpeg";
import vptb from "../Assets/Home/news/vptb.svg";
import { Row, Col } from 'react-bootstrap';


function MemphisMachine() {
    return (
        <div>
            <section className='newsContainerMain'>
                <div>
                    <div className='center'>
                        <img src={vptb} className="posterNews" alt='venky pens the blue poster' />
                    </div>

                    <p data-aos="fade-up"
                        data-aos-duration="2000" className='caSubHead'>The Memphis Machine</p>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <p className='newsParagraph'>This year, The Mahindra Blues has cherry-picked artists from across the classic schools of the Blues. And hence when it was the turn of Memphis, Brandon Santini's name immediately propped up. Raised in North Carolina before relocating to Memphis, Santini is one among the few young Blues' legends in contemporary music today. And this is also probably why he is on the road, almost all the time. There are chances that you have caught Santini performing in some part of the world, and if that is the case, you sure do know what is about to transpire.</p>
                            <p className='newsParagraph bold'>"Just one more mile…yeah, just one more mile to go it's been a long, desolate journey, girl, but I don't have to cry no more"</p>
                            <p className='newsParagraph'>One more mile <a href='https://www.youtube.com/watch?v=TPQSDFTZxBo' target="_blank" rel="noreferrer" className='linkCa'>https://www.youtube.com/watch?v=TPQSDFTZxBo</a> sums up everything that the band promises. You are introduced to the lush, thick vocals of Santini and his signature Harp lick as the song moves on. This was the first song of theirs that I listened to and this was enough to make me want to see him live. The energy that Santini, Timo Arthur (Guitar and Vocals), Chad Wirl (Percussion) and Nick Hern (Bass) bring to the stage is something that cannot be explained in words.</p>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <img src={img03} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                        </Col>
                    </Row>

                    <p className='newsParagraph'>At age 15, Santini first got wooed by the Harmonica and lists The Blues Traveler (Band) as one of his early influences. In an extremely interesting interview to the Bluesblast magazine <a href='http://www.bluesblastmagazine.com/issue-8-40-october-2-2014' target="_blank" rel="noreferrer" className='linkCa'>http://www.bluesblastmagazine.com/issue-8-40-october-2-2014</a>, he says:"At 15, as you can imagine, you listen to what's on mainstream radio and Blues Traveler...I heard those guys and I was like, 'Man, I really like that harmonica.' So, I bought one of their albums and I saw what kind of harmonica John Popper plays, so I asked my mom to take me to the music store and ended up buying a harmonica that day." Life is, indeed, that simple and it is legends like Santini who constantly remind us of this. I can talk in depth about the multiple awards he won as a musician (Including the prestigious Bluesblast awards) but I really want to talk more about the performer that Brandon Santini is.</p>
                    <br></br>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <img src={img003} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <p className='newsParagraph bold'>"I say, evil woman, where you going, and what's your name Yes, you know I love you baby, and I believe that's a crying shame."</p>
                            <p className='newsParagraph'> 'Evil Woman' <a href='https://www.youtube.com/watch?v=wOM9nipvWsU' target="_blank" rel="noreferrer" className='linkCa'>https://www.youtube.com/watch?v=wOM9nipvWsU</a>is definitely one that you should not miss. This is where his vocals are at their luscious best and the harmonica, from the time it begins, casts us into deep hypnosis. But an equally engaging performance (If not more powerful) is 'My Backscratcher' (https://www.youtube.com/watch?v=BFSDkI7fD7s) where it is not just Santini, but the entire band that brings the ceiling down with the energy. (Watch out for the percussion here)</p>
                        </Col>
                    </Row>
                    <p className='newsParagraph bold'>"Said, get on now woman, you'd better stay away from my door Cause I done found me a new backscratcher, I don't need you anymore"</p>
                    <br></br>
                    <Row>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <p className='newsParagraph bold'>Ouch</p>
                            <p className='newsParagraph'>The Harmonica speaks when Mr. Santini is in full flow and his band members complement him perfectly to create an experience that will definitely bring down Mehboob studios (Though, of course, we hope, not literally) in a fitting tribute to the Blues on Saturday night. This is definitely something you do not want to miss.
                                <br></br>
                                <br></br>
                                *Repeats for dramatic effect*: This is definitely something you would not want to miss.
                                <br></br>
                                <br></br>
                                <br></br>
                                -Venkat Iyer</p>
                        </Col>
                        <Col sm={12} xs={12} md={12} lg={6} xl={6}>
                            <img src={img0031} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                        </Col>

                    </Row>
                </div>
            </section>
        </div>
    )
}

export default MemphisMachine