import React from 'react';
import '../Styles/NewsStyle.css';
import img01 from "../Assets/Home/news/img01.png";
import vptb from "../Assets/Home/news/vptb.svg";


function Legacy() {
    return (
        <div> 
           <section className='newsContainerMain'>
            <div>
                <div className='center'>
                    <img src={vptb} className="posterNews" alt='venky pens the blue poster'/>
                </div>
                <img src={img01} className="newsImgStyle" alt="Venky Pens The Blue"></img>
                <p className='newsParagraph'><span className='italic'>The Mahindra Blues has a legacy of bringing together the Blues from across various schools to the Mehboob studios every year. This year though, artists have been cherry-picked to represent a mélange of influences, all coming together to create a memorable weekend. </span> At the Mahindra Blues 2019, you shall witness the Oregon Blues presented to you by Beth Hart, the Memphis Blues strung together in a rapturous performance by Brandon Santini, Texas Blues in a form you’ve never witnessed before by Sugaray Rayford and to top it off, Mississippi Blues in the language of the Harmonica by the legendary Charlie Musselwhite.</p>
                <p className='newsParagraph'>This playlist attempts to give you a brief glimpse into the world that is about to unfold on February 8th and 9th, by mixing up original pieces, live performances and memorable covers that these artists might put up on display. We highly recommend you to give this a listen, for when these legends play live, you would definitely want to have your ‘favorite’ piece by then.</p>

                <p className='newsParagraph'>Be it the emotional depth in ‘Caught out in the rain’ by Beth Hart, the raw energy in ‘My Backscratcher’ by Brandon Santini, the carefree bliss in “I’m in I’m out and I’m gone” by Charlie Musselwhite, the excruciating pain in “Death Letter Blues” by Sugaray Rayford or the technique that leaves you ensorcelled in Arinjoy Trio’s cover of “The Sky is Crying (Elmore James)”, this playlist comprises of 18 songs, fit in to run for about just an hour (For your drive from Churchgate to Bandra) and is designed to leave you in an highly anticipatory mood for this weekend.

So, who’re you going to look forward to the most, when the Blues come home, this year at the Mahindra Blues?</p>
<p className='newsParagraph'>
    <a href='https://www.youtube.com/playlist?list=PLzHPdsyP7vYUeFwhfzLKpY_k8oZ_yvfa_' target="_blank" rel="noreferrer" className='linkCa'>The Mahindra Blues 2019</a>

    <br></br>
    <br></br>
    -Venkat Iyer
</p>
            </div>
            </section>
        </div>
    )
}

export default Legacy